import {
  cpc_currentPrice,
  miniMumCartValue,
  cpc_data,
  cpc_configData,
  spaceRegex,
  specialCharactersRegex,
  cpc_product_default_price,
  cpc_metaFields,
} from "./global_variables";
import {
  globalHelper_Escape,
  globalHelper_ReportError,
  formatPricing,
  PreventCheckoutOnEnter,
} from "./common_utils";

import {
  getCurrentElementDataByIdenfier,
  generateSafeRegex,
} from "./everything_else";

import { RunNovaCurrencyConversionLogic, HasPriceTickerFormattedWithNovaCurrency } from "./nova_currency";

export function firstLoad() {
  let productForm;
  jQuery('form[action*="/cart/add"]').each(function (i, e) {
    if (productForm === undefined && e.querySelector('[type="submit"]')) {
      productForm = e;
    }
    if (productForm === undefined && e.querySelector('button[class*="cart"]')) {
      productForm = e;
    }
  });
  var radio = productForm.querySelector("input[type='radio']:checked");
  var dropDownImageSelector = productForm.querySelector(
    "select[data-showimage='true']"
  );
  if (radio !== undefined && radio !== null) {
    imageddlChange(radio);
  } else if (
    dropDownImageSelector !== undefined &&
    dropDownImageSelector !== null
  ) {
    imageddlChange(dropDownImageSelector);
  }
  ProcessConditionalElements();
  UpdatePrice();
  RunNovaCurrencyConversionLogic();
  PreventCheckoutOnEnter();
}

/*Every element change event will trigger this method */
export function ChangeEvent(elementType, domElement = null) {
  if (elementType === "ImageSelector") {
    imageddlChange(domElement);
  }
  ProcessConditionalElements();
  UpdatePrice();
}

function imageddlChange(element) {
  let isRadio = jQuery(element).is(":radio");
  var showImage = jQuery(element).data("showimage");
  if (isRadio === true) {
    //Swatch
    if (showImage === true) {
      let imgsrc = jQuery(element).data("imgsrc");
      UpdateProductImage(imgsrc);
    }
  } else {
    //Dropdown
    if (showImage === true) {
      let imgsrc = jQuery(element).children("option:selected").data("imgsrc");
      UpdateProductImage(imgsrc);
    }
  }
}

function UpdateProductImage(imgSrc) {
  var imageSelectors = document.querySelectorAll("[class^=product]>img");
  let isImageUpdated = false;
  let isPresentationUpdated = false;
  imageSelectors.forEach((elem) => {
    var pro_isHidden = jQuery(elem).is(":hidden");
    if (pro_isHidden === false) {
      if (isImageUpdated === false) {
        var calcSet = elem.getAttribute("calcset");
        var dataIndex = elem.getAttribute("data-index");
        var isDataIndexConditionSatisfied =
          dataIndex === null || dataIndex === "1";
        var storeURL = window.location.host + "/cdn";
        if (
          (elem.src.includes("cdn.shopify.com") ||
            elem.srcset.includes("cdn.shopify.com") ||
            elem.src.includes("myshopify.com") ||
            elem.srcset.includes("myshopify.com") ||
            elem.src.includes(storeURL) ||
            elem.srcset.includes(storeURL) ||
            calcSet === "true") &&
          isDataIndexConditionSatisfied === true
        ) {
          isImageUpdated = true;
          elem.setAttribute("src", imgSrc);
          elem.setAttribute("calcset", true);
          elem.setAttribute("data-srcset", "");
          elem.setAttribute("data-zoom", imgSrc);
          elem.setAttribute("srcset", "");
        }
      } else if (isImageUpdated === true && isPresentationUpdated === false) {
        var imageElement = document.querySelector('img[role="presentation"]');
        if (imageElement !== null && imageElement !== undefined) {
          isPresentationUpdated = true;
          imageElement.setAttribute("src", imgSrc);
          imageElement.setAttribute("calcset", true);
          imageElement.setAttribute("data-zoom", imgSrc);
          imageElement.setAttribute("data-srcset", "");
          imageElement.setAttribute("srcset", "");
        }
      }
    }
  });
}

export function ProcessConditionalElements() {
  jQuery(".element").each((d, elem) => {
    let conditionalElement = jQuery(elem).data("conditionalelement");
    let conditionalElementValue = jQuery(elem).data("conditionalelementvalue");
    if (conditionalElement !== undefined && conditionalElement !== null) {
      //Check if conditional Element is select
      var escapedConditionalElement = globalHelper_Escape(conditionalElement);
      let selectConditionalElement = document.querySelector(
        'select[data-identifier="' + escapedConditionalElement + '"]'
      );
      let radioConditionalElement = document.querySelector(
        'input[type="radio"][data-identifier="' +
        escapedConditionalElement +
        '"]:checked'
      );
      let checkboxConditionalElement = document.querySelector(
        'input[type="checkbox"][data-identifier="' +
        escapedConditionalElement +
        '"]'
      );
      let selectedValue = null;
      let isHidden = false;
      if (selectConditionalElement !== null) {
        selectedValue = jQuery(selectConditionalElement).val();
        isHidden = jQuery(selectConditionalElement).is(":hidden");
        // console.log('selectedValue', selectedValue)
      } else if (radioConditionalElement !== null) {
        selectedValue = jQuery(radioConditionalElement).val();
        isHidden = jQuery(radioConditionalElement).is(":hidden");
      } else if (checkboxConditionalElement !== null) {
        selectedValue =
          jQuery(checkboxConditionalElement).is(":checked") === true
            ? "Checked"
            : "Unchecked";
        isHidden = jQuery(checkboxConditionalElement).is(":hidden");
        // console.log('checkbox', checkboxConditionalElement)
      }

      //Show hide the conditional element if the value matches
      if (selectedValue !== null) {
        if (selectedValue == conditionalElementValue && isHidden === false) {
          jQuery(elem).show();
          jQuery(elem).find(".calc-prop").show();
        } else {
          jQuery(elem).hide();
          jQuery(elem).find(".calc-prop").hide();
        }
      }
    }
  });
}

export function UpdatePrice() {
  var formulaBeforeEval = "";
  try {
    var formula = cpc_data.Formula;
    EvaluateFormulaElements();

    //console.log('before-formula', formula)
    formula = FormulaParser(formula);
    if (formula === "NaN") formula = miniMumCartValue;
    // console.log('after-formula', formula)
    formulaBeforeEval = formula;
    var evaluatedNewPrice = eval(formula);
    //console.log('evaluated price', evaluatedNewPrice)
    var finalValue = Math.max(miniMumCartValue, evaluatedNewPrice);
    jQuery("#priceTicker").attr("data-origMoney", finalValue);
    if (finalValue != cpc_currentPrice && !isNaN(finalValue)) {
      //document.getElementById('priceTicker').innerHTML = jj;
      jQuery("#priceTicker")
        .prop("Counter", cpc_currentPrice)
        .animate(
          {
            Counter: finalValue,
          },
          {
            duration: 200,
            easing: "swing",
            step: function (now) {
              const formattedNumber = formatPricing(
                Number(now),
                +cpc_configData.thousandSeperator,
                +cpc_configData.decimalSeperator
              );
              if (!HasPriceTickerFormattedWithNovaCurrency(Number(now), "#priceTicker")) {
                jQuery(this).html(
                  formattedNumber
                );
              }
            }
          }
        );
      cpc_currentPrice = finalValue;
    }
    EvaluateCalculationElements();
  } catch (e) {
    var msg = "FormulaBeforeEval = " + formulaBeforeEval;
    globalHelper_ReportError(e, msg);
  }
}

//This will evaluate form
function EvaluateFormulaElements() {
  jQuery(".lookup-element-with-formula").each((d, elem) => {
    //console.log('lookup-element-',elem);
    let elem_formula = jQuery(elem).data("formula");
    let elem_formula_minvalue = jQuery(elem).data("minvalue");
    let parsedFormula = FormulaParser(elem_formula.toString());
    let evaluatedValue = eval(parsedFormula);
    let elem_finalValue = Math.max(elem_formula_minvalue, evaluatedValue);
    //console.log('formula 2', elem_finalValue)
    elem.setAttribute("value", elem_finalValue);
  });
}

function EvaluateCalculationElements() {
  jQuery(".calculation-element").each((d, elem) => {
    let elem_formula = jQuery(elem).data("formula");
    let elem_formula_minvalue = jQuery(elem).data("minvalue");
    let elem_current_value = jQuery(elem).data("val");
    let elem_decimals = jQuery(elem).data("decimals");
    let element_name = jQuery(elem).data("element_name");
    let parsedFormula = FormulaParser(elem_formula.toString());
    let evaluatedValue = eval(parsedFormula);
    let elem_finalValue = Math.max(elem_formula_minvalue, evaluatedValue);
    jQuery(elem).attr("data-origMoney", elem_finalValue);
    if (elem_finalValue != elem_current_value && !isNaN(elem_finalValue)) {
      jQuery(elem)
        .prop("Counter", elem_current_value)
        .animate(
          {
            Counter: elem_finalValue,
          },
          {
            duration: 200,
            easing: "swing",
            step: function (now) {
              let formattedNumber = Number(now).toFixed(elem_decimals);
              if (!HasPriceTickerFormattedWithNovaCurrency(Number(now), elem)) {
                jQuery(elem).html(
                  formattedNumber
                );
              }
            },
          }
        );
      jQuery(elem).data("val", elem_finalValue);
      element_name = element_name.replace(spaceRegex, "_");
      element_name = element_name.replace(specialCharactersRegex, "");
      jQuery("#calculation_inp_" + element_name).val(
        Number(elem_finalValue).toFixed(elem_decimals)
      );
      elem_current_value = elem_finalValue;
    }
  });
}

function FormulaParser(formula) {
  try {
    formula = formula.toLowerCase();
    var mathRegexPattern = new RegExp("math.", "g");
    formula = !!formula
      ? formula.toString().replaceAll(mathRegexPattern, "Math.")
      : "";
    formula = !!formula
      ? formula
        .toString()
        .replaceAll("shopify_product_price", cpc_product_default_price)
      : "";

    //apply meta fields
    cpc_metaFields.forEach((item) => {
      formula = !!formula
        ? formula.toString().replaceAll("shopify_meta_" + item.key, item.value)
        : "";
    });

    //Apply to remaining meta fields
    var metaRegexPattern = new RegExp("shopify_meta_(.*?)([*/+-]|$)", "g");
    let m;
    do {
      m = metaRegexPattern.exec(formula);
      if (m) {
        if (m.length > 1) {
          var metaVariableName = m[1];
          formula = !!formula
            ? formula
              .toString()
              .replaceAll("shopify_meta_" + metaVariableName, 1)
            : "";
          console.log("meta_variable_name", metaVariableName);
        }
      }
    } while (m);

    //formula = formula.replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "")
    var allElements = jQuery(jQuery(".calc-prop").get());
    var allElements = jQuery(jQuery(".calc-prop").get());
    allElements = allElements.sort((a, b) => {
      if (jQuery(a).data("identifier") === undefined) return 1;
      else if (jQuery(b).data("identifier") === undefined) return -1;
      else
        return jQuery(a).data("identifier").length >
          jQuery(b).data("identifier").length
          ? -1
          : 1;
    });

    var lookupElements = {};
    allElements.each((d, elem) => {
      let isHidden = jQuery(elem).is(":hidden");
      let isCheckBox = jQuery(elem).is(":checkbox");
      let isRadio = jQuery(elem).is(":radio");
      var elementType = jQuery(elem).prop("nodeName");
      let identifier = jQuery(elem).data("identifier");
      let rangeEnabled = jQuery(elem).data("rangeenabled");
      let lengthToggle = jQuery(elem).data("lengthtoggle");
      let isLookupElement = jQuery(elem).data("lookup") !== undefined;
      let IsLookupFormulaElement = isLookupElement
        ? jQuery(elem).data("isformula") === true
        : false;
      // console.log('isLookupElement', isLookupElement);
      if (identifier === undefined) return;
      if (identifier !== undefined) {
        var regexPattern = generateSafeRegex(identifier);

        if (
          isHidden === false ||
          (IsLookupFormulaElement === true &&
            jQuery(elem).css("display") != "none")
        ) {
          if (elementType == "SELECT") {
            let value = jQuery(elem).children("option:selected").data("val");
            formula = formula.replace(regexPattern, value);
          } else if (elementType == "INPUT" && rangeEnabled === true) {
            let value = jQuery(elem).val();
            value = parseFloat(value);

            var options = cpc_data.Elements;
            let finalInputValue = value;
            options.forEach((element) => {
              if (element.Identifier === identifier) {
                var rangeOptions = element.options;
                rangeOptions.forEach((element) => {
                  if (
                    value >= parseFloat(element.start) &&
                    value <= parseFloat(element.end)
                  ) {
                    finalInputValue = parseFloat(element.value);
                  }
                });
              }
            });

            //Handle case for actual value
            let actualValueIdentifier = identifier + "[ActualValue]";
            var actualValueRegex = generateSafeRegex(actualValueIdentifier);
            formula = formula.replace(actualValueRegex, value);

            //Range handling
            finalInputValue = parseFloat(finalInputValue, 10);
            formula = formula.replace(regexPattern, finalInputValue);
          }
          if (elementType == "TEXTAREA") {
            let value = jQuery(elem).val();
            let shouldCountWhiteSpace = lengthToggle;

            if (!shouldCountWhiteSpace) {
              // Remove white spaces from the value
              value = value.replace(/\s/g, "");
            }

            let length = value.length;

            let lengthIdentifier = identifier + "[length]";
            var lengthRegex = generateSafeRegex(lengthIdentifier);
            formula = formula.replace(lengthRegex, length);
          } else if (isCheckBox === true) {
            let isChecked = jQuery(elem).is(":checked");
            let checkedValue = jQuery(elem).data("checkedvalue");
            let uncheckedValue = jQuery(elem).data("uncheckedvalue");
            var valueToBeUsed = isChecked ? checkedValue : uncheckedValue;
            //console.log('checkedValue', valueToBeUsed)
            formula = formula.replace(regexPattern, valueToBeUsed);
          } else if (isRadio === true) {
            let isChecked = jQuery(elem).is(":checked");
            if (isChecked === true) {
              let value = jQuery(elem).data("val");
              formula = formula.replace(regexPattern, value);
            }
          } else if (isLookupElement === true) {
            let lookupelementindex = jQuery(elem).data("lookupelementindex");
            let elementIdentifier = jQuery(elem).data("identifier");
            let elementCalcName = jQuery(elem).data("calcname");
            if (typeof lookupElements[elementIdentifier] === "undefined") {
              var lookupElementProcessedElement = {
                elementName: elementIdentifier,
                input1_label: "",
                input1: 0,
                input2_label: "",
                input2: 0,
              };

              if (lookupelementindex === 1) {
                lookupElementProcessedElement.input1 = parseFloat(
                  jQuery(elem).val(),
                  10
                );
                lookupElementProcessedElement.input1_label = elementCalcName;
              } else if (lookupelementindex === 2) {
                lookupElementProcessedElement.input2 = parseFloat(
                  jQuery(elem).val(),
                  10
                );
                lookupElementProcessedElement.input2_label = elementCalcName;
              }

              lookupElements[elementIdentifier] = lookupElementProcessedElement;
            } else {
              let lookupElementProcessedElement =
                lookupElements[elementIdentifier];
              if (lookupelementindex === 1) {
                lookupElementProcessedElement.input1 = jQuery(elem).val();
                lookupElementProcessedElement.input1_label = elementCalcName;
              } else if (lookupelementindex === 2) {
                lookupElementProcessedElement.input2 = jQuery(elem).val();
                lookupElementProcessedElement.input2_label = elementCalcName;
              }
              //console.log('lookupElementProcessedElement', lookupElementProcessedElement);
            }
          } else {
            //console.log('regexPattern', regexPattern);
            //console.log('formula 5', formula);
            let value = jQuery(elem).val();
            value = parseFloat(value, 10);
            formula = formula.replace(regexPattern, value);
          }
        }
        //If the elemnt is not visible, take the not visible value
        else {
          var elementLookupResult = cpc_data.Elements.filter(function (
            item,
            idx
          ) {
            if (item.Name === identifier) return item;
          });

          //If the element is hidden then use default value
          if (elementLookupResult.length > 0) {
            let value = elementLookupResult[0].value_when_not_displayed;
            if (value === undefined) value = 1;

            //Handle special case for Actual value for range input
            if (elementType == "INPUT") {
              let actualValueIdentifier = identifier + "[ActualValue]";
              var actualValueRegex = generateSafeRegex(actualValueIdentifier);
              formula = formula.replace(actualValueRegex, value);
            }

            formula = formula.replace(regexPattern, value);
          }

          //Special handling for lookup element that is hidden
          if (isLookupElement === true) {
            let lookupelementindex = jQuery(elem).data("lookupelementindex");
            let lookupElementName = jQuery(elem).data("identifier");
            let lookupElementCalcName = jQuery(elem).data("calcname");
            let lookupElementResult = cpc_data.Elements.filter(function (
              item,
              idx
            ) {
              if (item.Name === lookupElementName) return item;
            });
            if (lookupElementResult.length > 0) {
              let lookupElementIdentifier =
                lookupElementName + "[" + lookupElementCalcName + "]";
              regexPattern = generateSafeRegex(lookupElementIdentifier);
              formula = formula.replace(regexPattern, 0);

              //Update the main element value when both elements are parsed
              if (lookupelementindex === 2) {
                let value = lookupElementResult[0].value_when_not_displayed;
                if (value === undefined) value = 1;
                regexPattern = generateSafeRegex(lookupElementName);
                value = parseFloat(value, 10);
                formula = formula.replace(regexPattern, value);
              }
            }
          }
        }
      }
    });

    //Now process lookup element
    for (var key in lookupElements) {
      let currentLookupElementData = lookupElements[key];
      var elementData = getCurrentElementDataByIdenfier(key);

      //Replace sub elements
      let lookupElementIdentifier1 =
        currentLookupElementData["elementName"] +
        "[" +
        currentLookupElementData["input1_label"] +
        "]";
      let lookupElementIdentifier2 =
        currentLookupElementData["elementName"] +
        "[" +
        currentLookupElementData["input2_label"] +
        "]";
      let regexPattern = generateSafeRegex(lookupElementIdentifier1);
      formula = formula.replace(
        regexPattern,
        currentLookupElementData["input1"]
      );
      regexPattern = generateSafeRegex(lookupElementIdentifier2);
      formula = formula.replace(
        regexPattern,
        currentLookupElementData["input2"]
      );

      let xIndex = elementData.sheet_data.input1_range.length - 1;
      let yIndex = elementData.sheet_data.input2_range.length - 1;
      for (
        let step = 0;
        step < elementData.sheet_data.input1_range.length - 1;
        step++
      ) {
        if (
          parseFloat(currentLookupElementData.input1) >=
          parseFloat(elementData.sheet_data.input1_range[step]) &&
          parseFloat(currentLookupElementData.input1) <
          parseFloat(elementData.sheet_data.input1_range[step + 1])
        ) {
          xIndex = step;
          break;
        }
      }
      for (
        let step = 0;
        step < elementData.sheet_data.input2_range.length - 1;
        step++
      ) {
        if (
          parseFloat(currentLookupElementData.input2) >=
          parseFloat(elementData.sheet_data.input2_range[step]) &&
          parseFloat(currentLookupElementData.input2) <
          parseFloat(elementData.sheet_data.input2_range[step + 1])
        ) {
          yIndex = step;
          break;
        }
      }

      //If the input is greater than the range then take the last element
      if (
        xIndex === 0 &&
        parseFloat(currentLookupElementData.input1) >
        parseFloat(
          elementData.sheet_data.input1_range[
          elementData.sheet_data.input1_range.length - 1
          ]
        )
      ) {
        xIndex = elementData.sheet_data.input1_range.length - 1;
      }
      //If the input is greater than the range then take the last element
      if (
        yIndex === 0 &&
        parseFloat(currentLookupElementData.input2) >
        parseFloat(
          elementData.sheet_data.input2_range[
          elementData.sheet_data.input2_range.length - 1
          ]
        )
      ) {
        yIndex = elementData.sheet_data.input2_range.length - 1;
      }

      let value = elementData.sheet_data.data_lookup[yIndex][xIndex];
      regexPattern = generateSafeRegex(key);
      formula = formula.replace(regexPattern, value);
    }

    //console.log(formula);
    return formula;
  } catch (e) {
    globalHelper_ReportError(e);
  }
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const PriceUpdation = debounce(function (element) {
  UpdatePrice();
}, 500);

