var cpc_currentPrice = 0;
var miniMumCartValue = 0;
var priceMarkerDecimals = 0;
var baseVariantId = 0;
var cpc_newVariantId = 0;
var cpc_calculatorId = "";
var cpc_waitforcartconfirmation = true;
var baseCalculatorNewApiUrl = __BASE_API_URL__;
var calculatorStyling = null;
var cpc_data = {};
var cpc_configData =
  parseJSON(localStorage.getItem("cpc_configData"), {}) || {};
var spaceRegex = new RegExp(" ", "g");
var specialCharactersRegex = /[^\w\s]/gi;
var cpc_shopUrl = "apippa";
var cpc_product_default_price = 0.0;
var cpc_pageLoaded = false;
var cpc_isPreview = false;
var cpc_productId = 0;
var cpc_metaFields = [];
var productForm;
var cpc_fasterCheckoutConfig = null;
var isError = false;
var cdnURL = __CDN_URL__;
var cpc_languageCode = "EN";

function parseJSON(inputString, fallback) {
  if (inputString) {
    try {
      return JSON.parse(inputString);
    } catch (e) {
      return fallback;
    }
  }
}

module.exports = {
  cpc_currentPrice,
  miniMumCartValue,
  priceMarkerDecimals,
  baseVariantId,
  cpc_calculatorId,
  cpc_waitforcartconfirmation,
  baseCalculatorNewApiUrl,
  calculatorStyling,
  cpc_data,
  cpc_configData,
  spaceRegex,
  specialCharactersRegex,
  cpc_shopUrl,
  cpc_product_default_price,
  cpc_pageLoaded,
  cpc_isPreview,
  cpc_productId,
  cpc_metaFields,
  productForm,
  cpc_fasterCheckoutConfig,
  isError,
  cdnURL,
  cpc_languageCode
};
