import { ProcessCartPage } from "./process_cart_page";
import { ProcessProductPage } from "./process_product_page";
import {
  cpc_shopUrl,
  cpc_pageLoaded,
  cpc_isPreview,
  cpc_productId,
  baseCalculatorNewApiUrl,
  cpc_languageCode,
  cpc_configData
} from "./global_variables";
import { ProcessNonProductPage } from "./process_nonproduct_page";
import "./plugin.css.js";
require("jquery-ui/themes/base/core.css");
require("jquery-ui/themes/base/base.css");
require("jquery-ui/themes/base/theme.css");

$(document).ready(function () {
  if (typeof String.prototype.replaceAll === "undefined") {
    String.prototype.replaceAll = function (match, replace) {
      return this.replace(new RegExp(match, "g"), () => replace);
    };
  }
  if (cpc_pageLoaded === false) {
    cpc_pageLoaded = true;
    console.log("calculator loading");
    if (typeof Shopify !== "undefined") {
      cpc_shopUrl = Shopify.shop;
      cpc_languageCode = Shopify.locale;
      if (window.meta.product !== undefined) {
        cpc_productId = window.meta.product.id;
      }
    } else {
      console.log("calculator loading in preview mode");
      var getUrl = $("#calculator_preview_storeurl").val();
      cpc_shopUrl = getUrl;
      cpc_isPreview = true;
    }

    //Only load calculator if its not loaded already
    var idUpdated = document.querySelector('select[name="oldId"]');
    if (idUpdated == null) DetectPage();
  }
});

//This method detects the page and runs the required script
async function DetectPage() {
  const path = window.location.pathname;
  const pageUrl = window.location.href;

  var calcNonProducts = await getNonProductByPageUrl(pageUrl);
  if (!!calcNonProducts) ProcessNonProductPage(calcNonProducts);
  else {
    const potentialHandle = path.substring(path.lastIndexOf("/") + 1);
    switch (potentialHandle.toUpperCase()) {
      case "CART":
        ProcessCartPage();
        break;

      default:
        var renderingDelayInMs = cpc_configData.renderingDelayInMs || 0;
        console.log('cpc-rendering-delay-configured', renderingDelayInMs)
        setTimeout(function() {
          ProcessProductPage();
        }, renderingDelayInMs);
        break;
    }
  }
}

async function getNonProductByPageUrl(pageUrl) {
  var url = `${baseCalculatorNewApiUrl}calculator/app/pageurl?pageUrl=${pageUrl}`;
  const response = await fetch(url);
  if (response.ok && response.status !== 204) return await response.json();

  return null;
}
