import { cpc_data } from "./global_variables";
export function ValidateInputs() {
  let isError = false;
  window.jQuery(".calc-prop").each((d, elem) => {
    let isHidden = window.jQuery(elem).is(":hidden");
    if (isHidden === false) {
      let value = window.jQuery(elem).val();
      let isRequired = window.jQuery(elem).data("isrequired");
      let isChecked = window.jQuery(elem).is(":checked");
      let elementType = elem.type;
      if (
        isRequired !== undefined &&
        isRequired == true &&
        ((elementType === "checkbox" && isChecked === false) ||
          (elementType !== "checkbox" && value.length <= 0))
      ) {
        console.log("element-if-clause", elem);
        isError = true;
        window.jQuery(elem).addClass("error-border");
      }
    }
  });

  setTimeout(() => {
    ResetInputs();
  }, 3000);

  return isError;
}

export function getCurrentElementDataByIdenfier(identifier) {
  var allelements = cpc_data.Elements;
  var finalElement = null;
  allelements.forEach((element) => {
    if (element.Identifier === identifier) {
      finalElement = element;
    }
  });
  return finalElement;
}

export function generateSafeRegex(identifier) {
  let identifierForFormula = identifier.toLowerCase();
  identifierForFormula = identifierForFormula.replace(/\(/g, "\\("); // (
  identifierForFormula = identifierForFormula.replace(/\)/g, "\\)"); // )
  identifierForFormula = identifierForFormula.replace(/\[/g, "\\["); // [
  identifierForFormula = identifierForFormula.replace(/\]/g, "\\]"); // ]
  identifierForFormula = identifierForFormula.replace(/\?/g, "\\?"); // ?
  identifierForFormula = identifierForFormula.replace(/\//g, "\\/"); // /
  identifierForFormula = identifierForFormula.replace(/\+/g, "\\+"); // +
  identifierForFormula = identifierForFormula.replace(/\$/g, "\\$"); // $
  identifierForFormula = identifierForFormula.replace(/\€/g, "\\€"); // €
  identifierForFormula = identifierForFormula.replace(/\£/g, "\\£"); // £
  identifierForFormula = identifierForFormula.replace(/\¥/g, "\\¥"); // ¥  
  identifierForFormula = identifierForFormula.replace(/\"/g, "\\\""); // "
  identifierForFormula = identifierForFormula.replace(/\'/g, "\\'"); // '

  var regexPattern = new RegExp(identifierForFormula, "g");
  return regexPattern;
}

function ResetInputs() {
  window.jQuery(".calc-prop").each((d, elem) => {
    window.jQuery(elem).removeClass("error-border");
  });
}

export function UpdateAllSelectedCheckBox() {
  window.jQuery(".checkbox-element .checkbox:checked").each((d, elem) => {
    window.jQuery(elem).val("Yes");
  });
}

export function UpdateAllHiddenElements() {
  window.jQuery("#calculator .element:hidden").each((d, elem) => {
    window.jQuery(elem).remove();
  });
}

function loadScript(url) {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

function loadCSS(url) {
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
}

export function loadScripts() {
  //CSS
  // loadCSS("https://code.jquery.com/ui/1.13.3/themes/base/jquery-ui.css");
  loadCSS(
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css"
  );

  //JS
  // loadScript("https://code.jquery.com/ui/1.13.3/jquery-ui.min.js");
  loadScript(
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/js/all.min.js"
  );
}
